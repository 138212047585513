<template>
	<div class="product-ebike">
        <section class="p-0 product-ebike_topImg">
            <div class="container-fluid">
                <div class="row">
                    <img alt="Portable Electric Vehicle Charging Cable Manufacturer - SINBON" :src="$data.siteUrl + '/img/ebike_banner.jpg'" class="w-100 product-ebike_topImg__img" />
                </div>
            </div>
        </section>

        <section class="py-5 breadcrumbsSection">
            <div class="container">
                <ol class="breadcrumbs">
                    <li>
                        <router-link :to="{ name:'home'}">{{ $t('nav_home') }}</router-link>
                    </li>
                    <li>{{ $t('nav_trends') }}</li>
                    <li>{{ $t('nav_ebike') }}</li>
                </ol>
            </div>
        </section>

        <section class="mt-5 mt-lg-0">
            <div class="container">
                <div class="row justify-content-between">
                    <div class="col">
                        <h1 class="h2">{{ $t('ebike_title') }}</h1>
                        <p class="sub-lead" v-html="$t('ebike_content_1')"></p>
                        <p class="sub-lead" v-html="$t('ebike_content_2')"></p>
                    </div>
                </div>
            </div>
        </section>




        <section class="py-6">
            <div class="container">
                <div class="row justify-content-between">
                    <div id="ebikeFeatureImg" class="col-md-6 p-0 px-sm-3">
                        <div class="product-ebike__bikeWrap">
                            <div v-show="accFeature==1" class="wiring-mask">
                                <div class="wiring-ring"></div>
                            </div>
                            <img v-show="accFeature==1" :src="$data.siteUrl + '/img/ebike-wiring.png'" alt="Wiring solution" />
                            <img v-show="accFeature==2" :src="$data.siteUrl + '/img/ebike-drive-system.png'" alt="Drive System Integration" />
                            <img v-show="accFeature==3" :src="$data.siteUrl + '/img/ebike-software.png'" alt="Software Solution" />
                            <img v-show="accFeature==4" :src="$data.siteUrl + '/img/ebike-addons.png'" alt="Flexible Add-ons" />
                        </div>
                    </div>
                    <div class="col-md-6 mt-5 mt-sm-0">
                        <!-- <h2>E-bike System Solutions</h2> -->
                        <div class="col-12 mb-0 p-0 h4">
                            <ul class="ebike-accordion accordion--right accordion-2 ">
                                <li :class="accFeature === 1 ? 'active' : ''">
                                    <h4 class="ebike-accordion__title w-100" @click="toggleFeature(1)">
                                        <span class="mb-0 font-weight-bold">{{ $t('ebike_part_desc_1_title') }}</span>
                                    </h4>
                                    <div class="ebike-accordion__content">
                                        <p class="mb-2">{{ $t('ebike_part_desc_1_content') }}</p>
                                        <ul class="bullets mx-0">
                                            <li>{{ $t('ebike_part_desc_1_list_1') }}</li>
                                            <li>{{ $t('ebike_part_desc_1_list_2') }}</li>
                                            <li>{{ $t('ebike_part_desc_1_list_3') }}</li>
                                        </ul>
                                    </div>
                                </li>
                                <li :class="accFeature === 2 ? 'active' : ''">
                                    <h4 class="ebike-accordion__title w-100" @click="toggleFeature(2)">
                                        <span class="mb-0 font-weight-bold">{{ $t('ebike_part_desc_2_title') }}</span>
                                    </h4>
                                    <div class="ebike-accordion__content">
                                        <p class="mb-2">{{ $t('ebike_part_desc_2_content') }}</p>
                                        <ul class="bullets mx-0">
                                            <li>{{ $t('ebike_part_desc_2_list_1') }}</li>
                                            <li>{{ $t('ebike_part_desc_2_list_2') }}</li>
                                            <li>{{ $t('ebike_part_desc_2_list_3') }}</li>
                                            <li>{{ $t('ebike_part_desc_2_list_4') }}</li>
                                            <li>{{ $t('ebike_part_desc_2_list_5') }}</li>
                                        </ul>
                                    </div>
                                </li>
                                <li :class="accFeature === 3 ? 'active' : ''">
                                    <h4 class="ebike-accordion__title w-100" @click="toggleFeature(3)">
                                        <span class="mb-0 font-weight-bold">{{ $t('ebike_part_desc_3_title') }}</span>
                                    </h4>
                                    <div class="ebike-accordion__content">
                                        <p class="mb-2">{{ $t('ebike_part_desc_3_content') }}</p>
                                        <ul class="bullets mx-0">
                                            <li>{{ $t('ebike_part_desc_3_list_1') }}</li>
                                            <li>{{ $t('ebike_part_desc_3_list_2') }}</li>
                                        </ul>
                                    </div>
                                </li>
                                <li :class="accFeature === 4 ? 'active' : ''">
                                    <h4 class="ebike-accordion__title w-100" @click="toggleFeature(4)">
                                        <span class="mb-0 font-weight-bold">{{ $t('ebike_part_desc_4_title') }}</span>
                                    </h4>
                                    <div class="ebike-accordion__content">
                                        <p class="mb-2">{{ $t('ebike_part_desc_4_content') }}</p>
                                        <ul class="bullets mx-0">
                                            <li>{{ $t('ebike_part_desc_4_list_1') }}</li>
                                            <li>{{ $t('ebike_part_desc_4_list_2') }}</li>
                                            <li>{{ $t('ebike_part_desc_4_list_3') }}</li>
                                            <li>{{ $t('ebike_part_desc_4_list_4') }}</li>
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <router-link :to="{path:'/product',query:{'cate':'communication,e-bike-solutions','page':'1'}}" class="inner-link type--uppercase btn btn--primary btn--md mt-3">
                            <span class="btn__text">{{ $t('ebike_related_products') }}</span>
                        </router-link>
                    </div>
                </div>
            </div>
        </section>


        <section class="imageblock switchable switchable--switch height-50 bg--secondary">
            <div class="imageblock__content col-lg-6 col-md-4 pos-right">
                <div class="background-image-holder">
                    <img :alt="$t('ebike_features_1_title')" :src="$data.siteUrl + '/img/ebike-img-1.jpg'" />
                </div>
            </div>
            <div class="container pos-vertical-center sub-lead">
                <div class="row">
                    <div class="col-lg-5 col-md-7">
                        <h3>{{ $t('ebike_features_1_title') }}</h3>
                        <p>{{ $t('ebike_features_1_content_1') }}</p>
                        <p>{{ $t('ebike_features_1_content_2') }}</p>
                    </div>
                </div>
            </div>
        </section>

        <section class="imageblock switchable h-auto bg--secondary">
            <div class="imageblock__content col-lg-6 col-md-4 pos-right">
                <div class="background-image-holder">
                    <img :alt="$t('ebike_features_2_title')" :src="$data.siteUrl + '/img/ebike-img-2.jpg'" />
                </div>
            </div>
            <div class="container pos-vertical-top sub-lead">
                <div class="row">
                    <div class="col-lg-5 col-md-7">
                        <h3>{{ $t('ebike_features_2_title') }}</h3>
                        <p>{{ $t('ebike_features_2_content_1') }}</p>
                        <p>{{ $t('ebike_features_2_content_2') }}</p>
                    </div>
                </div>
            </div>
        </section>

        <section class="imageblock switchable switchable--switch height-50 bg--secondary">
            <div class="imageblock__content col-lg-6 col-md-4 pos-right">
                <div class="background-image-holder">
                    <img :alt="$t('ebike_features_3_title')" :src="$data.siteUrl + '/img/ebike-img-3.jpg'" />
                </div>
            </div>
            <div class="container pos-vertical-center sub-lead">
                <div class="row">
                    <div class="col-lg-5 col-md-7">
                        <h3>{{ $t('ebike_features_3_title') }}</h3>
                        <p>{{ $t('ebike_features_3_content_1') }}</p>
                    </div>
                </div>
            </div>
        </section>

        <section class="imageblock switchable h-auto bg--secondary">
            <div class="imageblock__content col-lg-6 col-md-4 pos-right">
                <div class="background-image-holder">
                    <img :alt="$t('ebike_features_4_title')" :src="$data.siteUrl + '/img/ebike-img-4.jpg'" />
                </div>
            </div>
            <div class="container pos-vertical-top sub-lead">
                <div class="row">
                    <div class="col-lg-5 col-md-7">
                        <h3>{{ $t('ebike_features_4_title') }}</h3>
                        <p>{{ $t('ebike_features_4_content_1') }}</p>
                        <p>{{ $t('ebike_features_4_content_2') }}</p>
                    </div>
                </div>
            </div>
        </section>

        <section class="imageblock switchable switchable--switch height-50 bg--secondary">
            <div class="imageblock__content col-lg-6 col-md-4 pos-right">
                <div class="background-image-holder">
                    <img :alt="$t('ebike_features_5_title')" :src="$data.siteUrl + '/img/ebike-img-5.jpg'" />
                </div>
            </div>
            <div class="container pos-vertical-center sub-lead">
                <div class="row">
                    <div class="col-lg-5 col-md-7">
                        <h3>{{ $t('ebike_features_5_title') }}</h3>
                        <p>{{ $t('ebike_features_5_content_1') }}</p>
                    </div>
                </div>
            </div>
        </section>

        <section class="imageblock switchable h-auto bg--secondary">
            <div class="imageblock__content col-lg-6 col-md-4 pos-right">
                <div class="background-image-holder">
                    <img :alt="$t('ebike_features_6_title')" :src="$data.siteUrl + '/img/ebike-img-6.jpg'" />
                </div>
            </div>
            <div class="container pos-vertical-top sub-lead">
                <div class="row">
                    <div class="col-lg-5 col-md-7">
                        <h3>{{ $t('ebike_features_6_title') }}</h3>
                        <p>{{ $t('ebike_features_6_content_1') }}</p>
                    </div>
                </div>
            </div>
        </section>







        <section class="ebike-links py-5">
            <div class="container">
                <div class="row justify-content-between">
                    <div class="col-12 py-5">
                        <div class="ebike-links__item boxed imagebg mb-0" data-overlay="5">
                            <div class="background-image-holder">
                                <img alt="" :src="$data.siteUrl + '/img/ebike_solution_link_bg.png'" />
                            </div>
                            <div class="col-md-8 mx-auto">
                                <h4 class="mt-4">{{ $t('ebike_links_title') }}</h4>
                                <router-link to="/solution/integrated-solutions/connect-deeper-perform-seamless-integration-explore-our-e-bike-system-solution" class="btn mb-4 type--uppercase h6 font-weight-normal pl-4 pr-4">
                                    {{ $t('ebike_links_btn') }}
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="text-center bg--primary">
          <div class="container">
            <div class="row">
              <div class="col-md-10 mb-3">
                <h3 v-html="$t('ebike_contact_title')"></h3>
                <div class="row text-center">
                    <div class="col-sm-4">
                        <input
                            type="submit"
                            data-notification-link="contact"
                            :value="$t('ev_contact_btn')/*聯絡我們*/"
                            class="btn btn--primary type--uppercase inner-link text-secondary"
                        >
                    </div>
                </div>
              </div>
            </div>
          </div>
        </section>
    </div>
    
</template>
<script>
    import {setMetaInfo} from "@/libs/js/metaInfo.js";
    export default {
        data:function(){
            return {
                popupNum: '',
                meta:{
                    title: 'E-bike System Solutions',
                    desc: 'SINBON offers E-bike System Solutions. We provide a full range of customized and integrated services.',
                    img: location.protocol +'//'+ location.host + '/img/ebike_thumb.jpg'
                },
                metaData:{},
                accFeature: 1,
            }
        },
        metaInfo() {
            return this.metaData
        },
        methods:{
            toggleFeature(num){
                this.accFeature = num;
                // $('.main-container') scroll to #ebikeFeatureImg
                if(window.innerWidth < 768){
                    var _csy=$('.main-container')[0].scrollTop;
                    $('.main-container').animate({
                        scrollTop: $('#ebikeFeatureImg').offset().top+_csy-65
                    }, 500);
                }
            }
        },
        created() {
            setMetaInfo( this, this.meta.title, this.meta.url, this.meta.desc, this.meta.img);
        },
        mounted(){
            $('.ebike-accordion__title').on('click', function () {
                $(this).parents('li').addClass('active').siblings().removeClass('active');
            });
            this.$toggleLoading(false);
        }
    };

</script>