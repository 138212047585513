<template>
  <div>
    <section class="cover imagebg text-left space--sm" data-overlay="6">
      <div class="background-image-holder">
        <img alt="An investor is check financial report with his laptop" :src="$data.siteUrl + '/img/banner-investors.jpg'" />
      </div>
      <div class="container">
        <div class="row">
          <div class="col-md-8 col-lg-7">
            <h1 v-html="$t('contact_title')">聯絡與洽詢</h1>
          </div>
        </div>
      </div>
    </section>
    <section class="py-5 breadcrumbsSection">
      <div class="container">
        <ol class="breadcrumbs">
          <li>
            <router-link :to="{ name:'home'}" v-html="$t('nav_home')">首頁</router-link>
          </li>
          <li v-html="$t('contact_title')">聯絡與洽詢</li>
        </ol>
      </div>
    </section>
    <section>
      <div class="container">
        <div class="row">

          <div class="col-12 col-md-6" >
            <div class="boxed border">
              <div class="contact_box__text">
                <p class="mb-2 h4" v-html="$t('contact_subtitle_1')">投資人關係與新聞連絡人暨公司治理</p>
                <p>
                  <span v-html="$t('csr_contact_group_1_item_1_title')">公司發言人(財務長)</span> <span v-html="$t('csr_contact_group_1_item_1_name')">張集州</span><br>
                  <a href="tel:886226989999" class="block h6 m-0">
                    +886-2-26989999 #5761
                  </a>
                </p>
              </div>
              <div data-notification-link="contact" class="contact__txtBtn--blue" @click="setInputContent('Investor-Relations-Contact-and-Corporate-Governance')">
                  <span v-html="$t('ui_contact')">立即連繫</span>
              </div>
            </div>
          </div>

          <div class="col-12 col-md-6" >
            <div class="boxed border">
              <div class="contact_box__text">
                <p class="mb-2 h4" v-html="$t('contact_subtitle_2')">企業永續發展</p>
                <p>
                  <span v-html="$t('contact_subtitle_2_des')">永續辦公室</span><br>
                  <a href="tel:886226989999" class="block h6 m-0">
                    +886-2-26989999 #5220
                  </a>
                </p>
              </div>
              <div data-notification-link="contact" class="contact__txtBtn--blue" @click="setInputContent('Sustainability-Development')">
                  <span class="btn__text" v-html="$t('ui_contact')">立即連繫</span>
              </div>
            </div>
          </div>
          
          <div class="col-12 col-md-6" >
            <div class="boxed border">
              <div class="contact_box__text">
                <p class="mb-2 h4" v-html="$t('contact_subtitle_3')">產品相關</p>
                <p v-html="$t('contact_subtitle_3_des')">業務負責單位</p>
              </div>
              <div data-notification-link="contact" class="contact__txtBtn--blue" @click="setInputContent('products')">
                  <span class="btn__text" v-html="$t('ui_contact')">立即連繫</span>
              </div>
            </div>
          </div>
        
          <div class="col-12 col-md-6" >
            <div class="boxed border">
              <div class="contact_box__text">
                <p class="mb-2 h4" v-html="$t('contact_subtitle_4')">解決方案相關</p>
                <p v-html="$t('contact_subtitle_4_des')">方案專家</p>
              </div>
              <div data-notification-link="contact" class="contact__txtBtn--blue" @click="setInputContent('solutions')">
                  <span class="btn__text" v-html="$t('ui_contact')">立即連繫</span>
              </div>
            </div>
          </div>

          <div class="col-12 col-md-6" >
            <div class="boxed border">
              <div class="contact_box__text">
                <p class="mb-2 h4" v-html="$t('contact_subtitle_5')">品牌代理</p>
                <p v-html="$t('contact_subtitle_5_des')">智能零組件事業處</p>
              </div>
              <div data-notification-link="contact" class="contact__txtBtn--blue" @click="setInputContent('Brand-Distribution')">
                  <span class="btn__text" v-html="$t('ui_contact')">立即連繫</span>
              </div>
            </div>
          </div>
        
          <div class="col-12 col-md-6" >
            <div class="boxed border">
              <div class="contact_box__text">
                <p class="mb-2 h4" v-html="$t('contact_subtitle_8')">從業道德</p>
                <p>
                  <span v-html="$t('contact_subtitle_8_des')">稽核室</span><br>
                  <a href="tel:886226989999" class="block h6 m-0">
                    +886-2-2698-9999 #5820
                  </a>
                </p>
              </div>
              <div data-notification-link="integrityContact" class="contact__txtBtn--blue" @click="setInputContent('business-conduct')">
                  <span class="btn__text" v-html="$t('ui_contact')">立即連繫</span>
              </div>
            </div>
          </div>

          <div class="col-12 col-md-6" >
            <div class="boxed border">
              <div class="contact_box__text">
                <p class="mb-2 h4" v-html="$t('contact_subtitle_7')">材料零組件相關採購</p>
                <p>
                  <span v-html="$t('csr_contact_group_2_item_1_title')">設備零件負責</span>&nbsp;<span v-html="$t('csr_contact_group_2_item_1_name')">孔寅芳</span><br>
                  <a href="tel:8651086404098" class="block h6 m-0">
                    +86-510-86404098 #3252
                  </a>
                </p>
              </div>
              <div data-notification-link="contact" class="contact__txtBtn--blue" @click="setInputContent('Procurement-Commodity-Issues')">
                  <span class="btn__text" v-html="$t('ui_contact')">立即連繫</span>
              </div>
            </div>
          </div>
          
          <div class="col-12 col-md-6" >
            <div class="boxed border">
              <div class="contact_box__text">
                <p class="mb-2 h4" v-html="$t('contact_subtitle_6')">企業庶務相關採購</p>
                <p>
                  <span v-html="$t('csr_contact_group_2_item_2_title')">行政相關</span>&nbsp;<span v-html="$t('csr_contact_group_2_item_2_name')">陳羽凡</span><br>
                  <a href="tel:886226989999" class="block h6 m-0">
                    +886-2-26989999 #5511
                  </a>
                </p>
              </div>
              <div data-notification-link="contact" class="contact__txtBtn--blue" @click="setInputContent('Procurement-Factory-Affairs')">
                  <span class="btn__text" v-html="$t('ui_contact')">立即連繫</span>
              </div>
            </div>
          </div>

          <div class="col-12 col-md-6" >
            <div class="boxed border">
              <div class="contact_box__text">
                <p class="mb-2 h4" v-html="$t('contact_subtitle_9')">人才招募</p>
                <p v-html="$t('contact_subtitle_9_des')">人才招募服務中心</p>
              </div>
              <div data-notification-link="contact" class="contact__txtBtn--blue" @click="setInputContent('Recruitment')">
                  <span class="btn__text" v-html="$t('ui_contact')">立即連繫</span>
              </div>
            </div>
          </div>
        
          <div class="col-12 col-md-6" >
            <div class="boxed border">
              <div class="contact_box__text">
                <p class="mb-2 h4" v-html="$t('contact_subtitle_10')">員工申訴</p>
                <p v-html="$t('contact_subtitle_10_des')">員工關係小組</p>
              </div>
              <div class="">
                <a href="mailto:isay@sinbon.com" class="h6 m-0 contact__txtBtn--blue">
                  <span class="btn__text" v-html="$t('ui_contact')">立即連繫</span>
                </a>
              </div>
            </div>
          </div>
        
          <div class="col-12 col-md-6" >
            <div class="boxed border">
              <div class="contact_box__text">
                <p class="mb-2 h4" v-html="$t('contact_subtitle_11')">其他問題與建議</p>
                <p></p>
              </div>
              <div data-notification-link="contact" class="contact__txtBtn--blue" @click="setInputContent('others')">
                  <span class="btn__text" v-html="$t('ui_contact')">立即連繫</span>
              </div>
            </div>
          </div>
        </div>

        <!-- <contact/> -->
      </div>
    </section>
  </div>
</template>



<style lang="less" scoped>
.boxed{
    height: calc(100% - 30px);
}
@media (max-width: 767px) {
    .boxed{
        height: auto;
    }
}
</style>



<script>
// import apibase from "@/static/apibase.js";
// import axios from "axios";
import { mapActions } from "vuex";
// import { readCookie } from "@/libs/js/cookie.js";
import {setMetaInfo} from "@/libs/js/metaInfo.js";
import Contact from '../components/ContactForm.vue';
export default {
  components: { Contact },
  data: function() {
    return {
      // props:['inquery','category','product','solution'],
      // contacts: null,
      metaData:{}
    }
  },
  metaInfo() {
      return this.metaData
  },
  methods: {
    ...mapActions(["setSelectedContactTopic"]),
    setInputContent( val ){
      this.setSelectedContactTopic(val);
    }
  },
  created() {
    setMetaInfo( this, this.$t('page_title_contact'), undefined, "Leave a contact with us if you have any questions regarding your cable or connector issues. We'll get back to you in no time.", undefined,false,true );
    // this.$window.initAll();
  },
  mounted() {
    this.$themeInitNotification();
    this.$toggleLoading(false);
  }
};
</script>